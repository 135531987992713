import clsx from "clsx";
import type React from "react";
import styles from "./Typography2.module.css";

type Size =
  | "40"
  | "36"
  | "32"
  | "27"
  | "24"
  | "22"
  | "20"
  | "18"
  | "16"
  | "14"
  | "13"
  | "12"
  | "11";

type LineHeight = "180" | "165" | "150" | "135" | "120";

type Color = "gray120" | "gray90" | "red90" | "link";

export type Typography2Props = {
  testId?: string;
  bold?: boolean;
  size?: Size;
  lineHeight?: LineHeight;
  color?: Color;
  children?: React.ReactNode;
  as?: React.ElementType;
} & React.HTMLAttributes<HTMLElement>;

export const Typography2: React.FC<Typography2Props> = ({
  testId,
  bold = false,
  size = "16",
  lineHeight,
  color = "gray120",
  as,
  children,
  className,
  ...rest
}) => {
  const lineHeightValue = () => {
    if (lineHeight) {
      return lineHeight;
    }

    switch (size) {
      case "40":
        return "135";
      case "36":
        return "135";
      case "32":
        return "135";
      case "27":
        return "135";
      case "24":
        return "150";
      case "22":
        return "150";
      case "20":
        return "150";
      case "18":
        return "150";
      case "16":
        return "150";
      case "14":
        return "165";
      case "13":
        return "165";
      case "12":
        return "165";
      case "11":
        return "165";
      default:
        return "165";
    }
  };

  const classNames = clsx(
    styles[`font-${size}px`],
    styles[`line-height-${lineHeightValue()}`],
    bold ? styles["font-weight-bold"] : styles["font-weight-default"],
    styles[`font-color-${color}`],
    className,
  );

  const Component = as || "div";

  return (
    <Component data-testid={testId} className={classNames} {...rest}>
      {children}
    </Component>
  );
};
